import React from "react";
import { Container, Box, Typography, Button } from "@mui/material";

import prod1 from "../../assets/product-sales/prod1.png";
import prod2 from "../../assets/product-sales/prod2.png";
import prod3 from "../../assets/product-sales/prod3.png";
import prod4 from "../../assets/product-sales/prod4.png";

import prodCover from "../../assets/product-sales/prodcover1.png";

import prod11 from "../../assets/product-sales/prod11.png";
import prod12 from "../../assets/product-sales/prod12.png";
import prod13 from "../../assets/product-sales/prod13.png";
import prod14 from "../../assets/product-sales/prod14.png";
import prod15 from "../../assets/product-sales/prod15.png";

import downloadAttachment from "../utils/downloadAttachment";

const scannerList = [
  { prodImg: prod11, prodName: "ZG Scan 717" },
  { prodImg: prod12, prodName: "Regel Scan" },
  { prodImg: prod13, prodName: "Marvel Scan" },
  { prodImg: prod14, prodName: "Alta Scan" },
  { prodImg: prod15, prodName: "Hyper Scan" },
];

const data = [
  {
    content: {
      title: "High Efficiency ",
      dataContent:
        "22 laser lines + extra single laser line for quality scanning + 5 line for fine scanning Fast scanning up to 2100000 measurements/s",
    },
    image: prod1,
  },
  {
    content: {
      title: "Portability Design",
      dataContent:
        "Device is 0.83kg, can put it into suitcase and travel anywhere",
    },
    image: prod2,
  },
  {
    content: {
      title: "Amazing Adaptability",
      dataContent:
        "Intelligent guidance module to handle different surface easily",
    },
    image: prod3,
  },
  {
    content: {
      title: "Principle of Binocular Vision",
      dataContent:
        "Perfectly simulate the principle of human eyes' 3D imaging in scanning     Generate high quality 3D model",
    },
    image: prod4,
  },
];
const ProductImage = ({ image }) => (
  <Box sx={{ flexBasis: 0, flexGrow: 1 }}>
    <img
      src={image}
      alt={"facility"}
      width="100%"
      style={{
        borderRadius: "10px",
        boxShadow: "0px 4px 10px 0px #FFFFFF80",
      }}></img>
  </Box>
);

const ProductContent = ({ content }) => (
  <Box sx={{ flexBasis: 0, flexGrow: 1 }}>
    <Typography
      sx={{
        mb: 2,
        fontWeight: 700,
        fontSize: { xs: "20px", sm: "24px", md: "28px" },
        lineHeight: "45px",
      }}>
      {content.title}
    </Typography>
    <Typography
      sx={{
        fontWeight: 400,
        fontSize: { xs: "20px", sm: "24px", md: "28px" },
      }}>
      {content.dataContent}
    </Typography>
  </Box>
);

const ProductIntro = () => {
  return (
    <Container
      sx={{
        pb: 2,
        pt: 5,
      }}
      maxWidth={false}
      disableGutters>
      <Box
        sx={{
          backgroundImage: "url(" + prodCover + ")",
          backgroundSize: "cover",
          pt: 7,
          pb: 7,
        }}>
        <Typography
          sx={{
            textAlign: "center",
            mb: 3,
            fontWeight: 700,
            color: "#ffffff",
          }}
          variant="h5">
          HAND-HELD/PORTABLE 3D LASER SCANNERS
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            flexDirection: { xs: "column", sm: "column", md: "row" },
          }}>
          {scannerList.map((scannerListItem, index) => (
            <Box sx={{ textAlign: "center" }}>
              <img
                src={scannerListItem.prodImg}
                alt="tech spec"
                key={index}
                width="70%"
              />
              <Typography variant="h6" sx={{ mt: 4, color: "#ffffff" }}>
                {scannerListItem.prodName}
              </Typography>
            </Box>
          ))}
        </Box>
        <Box sx={{ pt: 4, textAlign: "center" }}>
          <Button
            onClick={() => downloadAttachment("ZG_Scanner.pdf")}
            variant="outlined"
            sx={{
              textDecoration: "none",
              color: "#ffffff",
              textTransform: "capitalize",
            }}>
            Download Brochure
          </Button>
        </Box>
      </Box>
      <Box sx={{ pl: 10, pr: 10 }}>
        <Typography sx={{ textAlign: "center", mb: 4, mt: 4 }} variant="h4">
          Product Description
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: "20px", sm: "24px", md: "28px" },
            fontWeight: 400,
            mb: 2,
          }}>
          ZG Scan Series Smart Handheld 3D Laser Scanner with triangulation
          technology can support efficiency scan various part in different size,
          materiais, etc. The collection data can used for 3D inspection,
          reverse design, 3D printing and other areas which greatly meet the
          needs of R&D and Quality Control department. ZG Scan 717 has
          independent intellectual property right, granted with multiple
          national invention patents and certified by National Institute of
          Metrology of China.
        </Typography>
      </Box>
      <Box sx={{ pl: 10, pr: 10 }}>
        <Typography sx={{ textAlign: "center", mb: 4, mt: 4 }} variant="h4">
          Product Features
        </Typography>
        {data.map((ele, index) =>
          index % 2 === 0 ? (
            <Container
              maxWidth={false}
              disableGutters
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "column", md: "row" },
                gap: "3%",
                p: 2,
                flexWrap: "wrap",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              key={index}>
              <ProductImage {...ele} />
              <ProductContent {...ele} />
            </Container>
          ) : (
            <Container
              maxWidth={false}
              disableGutters
              sx={{
                display: "flex",
                flexDirection: {
                  xs: "column-reverse",
                  sm: "column-rverse",
                  md: "row",
                },
                gap: "3%",
                p: 2,
                flexGrow: 1,
                justifyContent: "space-between",
                alignItems: "center",
              }}
              key={index}>
              <ProductContent {...ele} /> <ProductImage {...ele} />
            </Container>
          )
        )}
      </Box>
    </Container>
  );
};

export default ProductIntro;
