import { Container, Typography } from "@mui/material";
import AW from "../../assets/pmt-products/AW.png";
import React from "react";

const Award = () => {
  return (
    <Container disableGutters maxWidth={false} sx={{ textAlign: "center" }}>
      <Typography variant="h4" sx={{ mb: 4 }}>
        Awards & Certifications
      </Typography>
      <img src={AW} alt="Awards and Certification" width="40%"></img>
    </Container>
  );
};

export default Award;
