import React, { useEffect } from "react";
import Banners from "../components/homepage/Banners";
import Facility from "../components/homepage/Facility";
import NewsEvents from "../components/homepage/NewsEvents";
import CompanyInfo from "../components/homepage/CompanyInfo";
import IndustryServe from "../components/homepage/IndustryServe";
import VideoInfo from "../components/homepage/VideoInfo";
import Clients from "../components/homepage/Clients";

const Home = () => {
  useEffect(() => {
    document.title = "ams3d";
  }, []);
  return (
    <div>
      <Banners />
      <CompanyInfo />
      <Facility />
      <IndustryServe />
      <VideoInfo />
      <NewsEvents />
      <Clients />
    </div>
  );
};

export default Home;
