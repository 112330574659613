import React from "react";

// Import Pages
import Home from "./pages/Home";
import ProductSales from "./pages/ProductSales";
import MetrologyServices from "./pages/MetrologyServices";
import Digitisation from "./pages/Digitisation";
import Careers from "./pages/Careers";
import ContactUs from "./pages/ContactUs";
import PmtProducts from "./pages/PmtProducts";
import Accessories from "./pages/Accessories";

const routes = () => {
  //   useEffect(() => {}, []);
  return [
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/zg-products",
      element: <ProductSales />,
    },
    {
      path: "/pmt-products",
      element: <PmtProducts />,
    },
    {
      path: "/accessories",
      element: <Accessories />,
    },
    {
      path: "/metrology-services",
      element: <MetrologyServices />,
    },
    {
      path: "/digitisation",
      element: <Digitisation />,
    },
    {
      path: "/careers",
      element: <Careers />,
    },
    {
      path: "/contact-us",
      element: <ContactUs />,
    },
  ];
};

export default routes;
