import { Container, Box, Typography, Button } from "@mui/material";
import prodCover from "../../assets/pmt-products/prodCover.png";
import React from "react";

import prod1 from "../../assets/pmt-products/prod1.png";
import prod2 from "../../assets/pmt-products/prod2.png";
import prod3 from "../../assets/pmt-products/prod3.png";
import prod4 from "../../assets/pmt-products/prod4.png";

import downloadAttachment from "../utils/downloadAttachment";

const productList = [
  { prodImg: prod1, prodName: "Alpha P 6 axis/7 axis" },
  { prodImg: prod2, prodName: "Alpha M 6 axis/7 axis," },
  { prodImg: prod3, prodName: "Alpha EDU 6 axis/7 axis" },
  { prodImg: prod4, prodName: "Alpha E 6 axis" },
];

const Products = () => {
  return (
    <Container
      sx={{
        backgroundImage: "url(" + prodCover + ")",
        backgroundSize: "cover",
        p: 10,
        pt: 8,
        pb: 8,
        mb: 2,
      }}
      maxWidth={false}
      disableGutters>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          flexDirection: { xs: "column", sm: "column", md: "row" },
        }}>
        {productList.map((productListItem, index) => (
          <Box
            sx={{
              textAlign: "center",
              flex: 1,
            }}>
            <img
              src={productListItem.prodImg}
              alt="tech spec"
              key={index}
              width="70%"
            />
            <Typography variant="h6" sx={{ mt: 4 }}>
              {productListItem.prodName}
            </Typography>
          </Box>
        ))}
      </Box>
      <Box sx={{ mt: 5 }}>
        <Typography variant="h6" textAlign={"center"}>
          <Button
            onClick={() => downloadAttachment("AMS3D_PMT.pdf")}
            variant="outlined"
            sx={{
              textDecoration: "none",
              color: "#000000",
              cursor: "pointer",
              textTransform: "capitalize",
            }}>
            Download Brochure
          </Button>
        </Typography>
      </Box>
    </Container>
  );
};

export default Products;
