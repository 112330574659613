import React from "react";
import MainContent from "../components/pmt-products/MainContent";
import Products from "../components/pmt-products/Products";
// import Products2 from "../components/pmt-products/Products2";
import Applications from "../components/pmt-products/Applications";
import Award from "../components/pmt-products/Award";

const PmtProducts = () => {
  return (
    <>
      <MainContent />
      <Products />
      {/* <Box sx={{ pt: 3, pb: 3 }}>
        <Typography variant="h6" textAlign={"center"}>
          <Link
            onClick={() => downloadAttachment("AMS3D_PMT_Brochure.pdf")}
            sx={{
              textDecoration: "none",
              color: "#000000",
              cursor: "pointer",
            }}>
            Download Brochure
          </Link>
        </Typography>
      </Box> */}
      <Award />
      <Applications showApplications={true} />
    </>
  );
};

export default PmtProducts;
