import React from "react";
import { Box, duration, useTheme } from "@mui/material";

import Banner1 from "../../assets/Banner1.jpg";
import Banner2 from "../../assets/Banner2.jpg";
import Banner3 from "../../assets/Banner3.jpg";
import Banner4 from "../../assets/Banner4.jpg";
import Banner5 from "../../assets/Banner5.jpg";
import Banner6 from "../../assets/Banner6.jpg";

import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";

const AutoPlaySwipeAbleViews = autoPlay(SwipeableViews);

const bannerImgs = [
  {
    name: "banner2",
    path: Banner2,
  },
  {
    name: "banner5",
    path: Banner1,
  },
  // {
  //   name: "banner1",
  //   path: Banner1,
  // },

  // {
  //   name: "banner3",
  //   path: Banner3,
  // },
  {
    name: "banner4",
    path: Banner4,
  },

  // {
  //   name: "banner6",
  //   path: Banner6,
  // },
];

const springConfig = { duration: "0.3s" };

const Banners = () => {
  const theme = useTheme();
  return (
    <AutoPlaySwipeAbleViews
      axis={theme.direction === "rtl" ? "x-reverse" : "x"}
      springConfig={springConfig}>
      {bannerImgs.map((step, index) => {
        return (
          <Box
            component="img"
            key={step.name}
            sx={{
              display: "block",
              overflow: "hidden",
              width: "100vw",
              maxWidth: "100%",
            }}
            src={step.path}
            alt={step.name}
          />
        );
      })}
    </AutoPlaySwipeAbleViews>
  );
};

export default Banners;
