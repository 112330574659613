import React from "react";
import { Container, Box, Typography } from "@mui/material";

// import TechSpec from "../../assets/product-sales/TechSpec.png";
import App1 from "../../assets/product-sales/App1.png";
import App2 from "../../assets/product-sales/App2.png";
import App3 from "../../assets/product-sales/App3.png";
import App4 from "../../assets/product-sales/App4.png";

const TechSpecApp = () => {
  return (
    <Container maxWidth={false} sx={{ mb: 5 }}>
      {/* <Box textAlign={"center"}>
        <Typography
          sx={{ textAlign: "center", mb: 4, mt: 4, fontWeight: 700 }}
          variant="h4">
          Technical Specifications
        </Typography>

        <img src={TechSpec} alt="tech spec" />
      </Box> */}
      <Box textAlign={"center"}>
        <Typography
          sx={{ textAlign: "center", mb: 4, mt: 4, fontWeight: 700 }}
          variant="h4">
          Applications
        </Typography>
        <Box>
          <Typography
            sx={{ textAlign: "center", mb: 4, mt: 4, fontWeight: 500 }}
            variant="h6">
            3D Dimensional Measurement of Sheet Metal
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              flexDirection: { xs: "column", sm: "column", md: "row" },
            }}>
            <img src={App1} alt="tech spec" />
            <img src={App2} alt="tech spec" />
          </Box>
          <Typography
            sx={{ textAlign: "center", mb: 4, mt: 4, fontWeight: 500 }}
            variant="h6">
            Great Adaptability and Flexible Data Processing
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              flexDirection: { xs: "column", sm: "column", md: "row" },
            }}>
            <img src={App3} alt="tech spec" />
            <img src={App4} alt="tech spec" />
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default TechSpecApp;
