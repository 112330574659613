import { Typography, Container, Box, Divider } from "@mui/material";
import React from "react";
import prodSalesBanner1 from "../../assets/product-sales/ProdSalesBanner1.gif";
import Prod1CoverOver from "../../assets/product-sales/Prod1CoverOver.png";

const MainContent = () => {
  return (
    <Container class="container" maxWidth={false} disableGutters>
      <Box>
        <div class="inner">
          <img
            src={prodSalesBanner1}
            alt="banner"
            style={{ width: "100vw", maxWidth: "100%" }}></img>
        </div>
      </Box>
      <Divider
        sx={{
          width: 0,
          height: 0,
          borderRight: "99.5vw solid transparent",
          borderBottom: "14vw solid #fff",
          // backgroundImage:
          //   "linear-gradient(to left, 180deg, #A0CBFF -4.86%, #AED3FF -4.84%, #879EBA 140.55%)",
          position: "relative",
          marginTop: "-14vw",
          maxWidth: "100%",
        }}
      />
      {/* <Box sx={{ position: "relative", mt: -40, ml: "20%" }}>
        <img src={Prod1CoverOver} alt="banner"></img>
      </Box> */}
      <Box sx={{ position: "relative", mt: -30, ml: "65%" }}>
        <img src={Prod1CoverOver} alt="banner" width="80%"></img>
      </Box>
      <Box
        sx={{
          mt: "-12vw",
          p: 10,
          pt: 5,
          background: "#fff",
        }}>
        <div style={{ position: "relative" }}>
          <Typography
            textAlign={"left"}
            sx={{ fontWeight: 700, color: "#00799E", letterSpacing: 2 }}
            variant="h5">
            <span style={{ fontSize: 45 }}>ZG </span>TECHNOLOGY 3D SCANNERS
            <br />
            About ZG Technology:
          </Typography>
          <Typography
            variant="h6"
            sx={{ fontSize: "24px", lineHeight: "30px", mt: 2 }}>
            ZG Technology Ltd. has more than 50 national patents and <br></br>
            software copyrights, and has obtained more than 20 qualification
            awards at or
            <br></br>
            above the national or ministerial level. A number of core
            technologies <br></br> independently researched and developed are at
            the international leading <br></br>level and are continuously
            updated. Relying on the background and technical advantages of Wuhan
            University's photogrammetry and remote sensing disciplines, ZG has
            always been at the forefront of industry technology. <br></br>In
            2014, ZG Technology was established in the city of Wuhan, China, and
            we have solution centers in Beijing, Changchun, Tianjin, Qingdao,
            Shanghai, Suzhou, Shenzhen, Xi’an, Chongqing and other cities. At
            present, our sales network has developed to the global market. With
            local distributors and partners in Europe, North America and
            Southeast Asia, ZG is a global high-tech company leading the field
            of vision and photogrammetry, committed to providing users with the
            best 3D digital solutions.
          </Typography>
        </div>
      </Box>
    </Container>
  );
};

export default MainContent;
