import React from "react";
import { Container, Box, Typography } from "@mui/material";
import ServiceInfoIMg from "../../assets/metrology-services/ServiceInfo.png";

const ServiceInfo = () => {
  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        mt: "5vw",
        pl: 10,
        pr: 10,
        pb: 5,
        pt: 5,
        background:
          "linear-gradient(180deg, #FFFFFF 0%, #A6BAEA 44.79%, #FFFFFF 100%)",
        textAlign: "justify",
      }}>
      <Box>
        <img
          src={ServiceInfoIMg}
          alt="serviceinfo"
          style={{
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
            width: "30%",
          }}
        />
        <Typography variant="h6" sx={{ mb: 2 }}>
          Whether you need to compare a CAD model to a manufactured part,
          reverse engineering a critical piece of equipment or perform detailed
          analysis on damaged components/parts, We at AMS 3D Metrology (I) Pvt
          Ltd has the expertise and resource to spread Quality/Precision in the
          industries. We have state-of-the-art Technologies & Software to
          provide 3D measurement Services accommodating to have convenient &
          sophisticated portray of the physical components.
        </Typography>
        <Typography variant="h6">
          Having Micron level of precision in the thousands of an inch we
          service companies involved in a variety of industries including
          aerospace, automotive, casting and forging as well as oil and gas,
          injection moulding, medical, chemical and power generation.
        </Typography>
      </Box>
    </Container>
  );
};

export default ServiceInfo;
