import React from "react";
import { Container, Box } from "@mui/material";
import AltaScan from "../../assets/product-sales/AltaScan.png";

const ProductsList = () => {
  return (
    <Container maxWidth={false} disableGutters sx={{ mb: 2 }}>
      <Box>
        <img
          src={AltaScan}
          alt="altascan"
          style={{ width: "100vw", maxWidth: "100%" }}
        />
      </Box>
      {/* <Box sx={{ pt: 3, pb: 3, textAlign: "center" }}>
        <Button
          variant="outlined"
          sx={{
            textDecoration: "none",
            color: "#000000",
            textTransform: "capitalize",
          }}>
          Download Brochure
        </Button>
      </Box> */}
    </Container>
  );
};

export default ProductsList;
