import React from "react";
import { Box } from "@mui/material";
import Banner from "../../assets/contact-us/Banner.png";
const Banners = () => {
  return (
    <Box
      component="img"
      sx={{
        display: "block",
        overflow: "hidden",
        width: "100vw",
        height: "40vw",
        maxWidth: "100%",
      }}
      src={Banner}
      alt={"Banner"}
    />
  );
};

export default Banners;
