const downloadAttachment = (pdfUrlLink) => {
  const pdfUrl = pdfUrlLink;
  const link = document.createElement("a");
  link.href = pdfUrl;
  link.download = pdfUrlLink; // specify the filename
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export default downloadAttachment;
