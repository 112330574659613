import React, { useEffect } from "react";
import Awards from "../components/product-sales/Awards";
import ProductIntro from "../components/product-sales/ProductIntro";
import TechSpecApp from "../components/product-sales/TechSpecApp";
import Services from "../components/product-sales/Services";
import ProductsList from "../components/product-sales/ProductsList";
import Advantages from "../components/product-sales/Advantages";
import MainContent from "../components/product-sales/MainContent";

const ProductSales = () => {
  useEffect(() => {
    document.title = "ams3d: Product Sales";
  }, []);

  return (
    <>
      <MainContent />
      <Awards />
      <ProductIntro />
      <TechSpecApp />
      <Advantages />
      <Services />
      <ProductsList />
    </>
  );
};

export default ProductSales;
