import React from "react";
import { Container, Box, Typography, Button } from "@mui/material";

const CompanyInfo = () => {
  return (
    <Container
      sx={{
        background: "linear-gradient(180deg, #8DAFBB 0%, #4D4D4D 155.32%)",
        p: 10,
        pb: 5,
        pt: 5,
      }}
      maxWidth={false}
      disableGutters>
      <Box>
        <Typography
          sx={{
            fontSize: { xs: "20px", sm: "24px", md: "28px" },
            fontWeight: 400,
            color: "#ffffff",
            mb: 2,
          }}>
          <span style={{ fontWeight: 700 }}>AMS 3D METROLOGY I PVT LTD</span> is
          one of the leading organisations when it comes to 3D Metrology
          Solutions for Sales & Services. We are technology driven company that
          provides varieties in range of design & engineering solutions for
          manufacturing industries. We offer 3D Scanning Solution.
        </Typography>
      </Box>
      <Box>
        <Typography
          sx={{
            fontSize: { xs: "20px", sm: "24px", md: "28px" },
            fontWeight: 400,
            color: "#ffffff",
          }}>
          Portable CMM Solutions as well as Reverse Engineering/Digitization
          Solutions. We have a team of experienced & eminent Engineers, with
          focus on simplifying our customer's process by offering solutions with
          unmatched quality to help them to improve operational efficiency
          reduce time to market foster product Innovation and reduce cost.
        </Typography>
      </Box>
      {/* <Box sx={{ textAlign: "center", mt: 3 }}>
        <Button
          sx={{
            border: "2px solid #ffffff",
            pl: 2,
            pr: 2,
            color: "#ffffff",
            borderRadius: "50px",
            fontSize: { xs: "14px", sm: "18px", md: "22px" },
          }}>
          Read More
        </Button>
      </Box> */}
    </Container>
  );
};

export default CompanyInfo;
