import { Container, Typography, Box } from "@mui/material";
import React from "react";

const PhotoGallery = ({ photoGalleryList }) => {
  return photoGalleryList.map((photoGalleryItem, index) => (
    <img
      src={photoGalleryItem}
      alt="photoGalleryItem"
      key={index}
      width="100%"
    />
  ));
};

const Advantage = ({ advantageList }) => {
  return advantageList.map((advantageItem, index) => (
    <Box
      key={index}
      sx={{
        mt: 2,
        display: "table",
        height: 80,
        background:
          "linear-gradient(90deg, rgba(217, 217, 217, 0) 0%, #D3CABB 51.88%, rgba(217, 217, 217, 0) 100%)",
        verticalAlign: "middle",
      }}>
      <Typography
        sx={{
          display: "table-cell",
          verticalAlign: "middle",
          textAlign: "center",
          fontWeight: 500,
        }}>
        {advantageItem}
      </Typography>
    </Box>
  ));
};

const MetroService = ({ service }) => {
  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{ pl: 10, pr: 10, pb: 5, pt: 5 }}>
      <Box>
        <Typography variant="h4" textAlign="center">
          {service.title}
        </Typography>
        <Box
          sx={{
            display: "grid",
            alignItems: "center",
            justifyContent: "center",
            gridTemplateColumns: "0.5fr 1.5fr",
            columnGap: "5%",
            textAlign: "justify",
            p: 5,
          }}>
          <img src={service.descImg} alt="metroservice" width="100%"></img>
          <Typography variant="h6">{service.description}</Typography>
        </Box>
      </Box>
      <Box>
        <Typography variant="h4" textAlign="center">
          {service.advTitle}
        </Typography>
        <Box
          sx={{
            display: "grid",
            alignItems: "center",
            gridTemplateColumns: "1fr 1fr",
            columnGap: "10%",
            textAlign: "justify",
            mt: 5,
          }}>
          <Advantage advantageList={service.advantageList} />
        </Box>
        <Box
          sx={{
            display: "grid",
            alignItems: "center",
            justifyItems: "center",
            gridTemplateColumns: "1fr 1fr 1fr",
            textAlign: "justify",
            pt: 10,
            gap: 10,
          }}>
          <PhotoGallery photoGalleryList={service.photoGalleryList} />
        </Box>
      </Box>
    </Container>
  );
};

export default MetroService;
