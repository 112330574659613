import React from "react";
import { Container, Box, Typography, Divider } from "@mui/material";

import "./Engineering.css";

import DRE1 from "../../assets/digitisation/DRE1.png";
import DRE2 from "../../assets/digitisation/DRE2.png";
import DRE3 from "../../assets/digitisation/DRE3.png";
import DRE4 from "../../assets/digitisation/DRE4.png";
import DRE5 from "../../assets/digitisation/DRE5.png";
import DRE6 from "../../assets/digitisation/DRE6.png";

import PD1 from "../../assets/digitisation/PD1.png";
import PD2 from "../../assets/digitisation/PD2.png";
import PD3 from "../../assets/digitisation/PD3.png";

import AD1 from "../../assets/digitisation/AD1.png";
import AD2 from "../../assets/digitisation/AD2.png";
import AD3 from "../../assets/digitisation/AD3.png";
import AD4 from "../../assets/digitisation/AD4.png";
import AD5 from "../../assets/digitisation/AD5.png";

const services = [
  {
    title: "Digital Reverse Engineering",
    desc: "Digital Reverse engineering is a process that involves measuring a physical object by 3D scanning method and reconstructing it as a 3D model.",
    images: [DRE1, DRE2, DRE3, DRE4, DRE5, DRE6],
    importance: "Why Digital Reverse Engineering is required ?",
    importanceList: [
      "Documentation missing for a part.",
      "Bad features of a part that needs to be redesigned, such as improving where a product is experiencing excessive wear.",
      "Analyse competitors’ products for performance and benchmarking.",
      "When the original CAD model is missing, and you need to make modifications or changes to your manufacturing methods.",
      "Parts Replacement",
      "Tear Down Analysis",
    ],
    adavantages: [
      "Reverse engineering can reproduce replacement parts as well as identify and help to correct faults in products.",
      "Reverse engineering can accelerate product innovation.",
      "Manufacturers use reverse engineering to quickly produce parts rather than purchase components from an OEM that may have longer lead times and demand premium costs.",
      "Reverse engineering can also be a key strategy in a manufacturer’s proactive maintenance plan.",
      "Creating a reliable CAD model for future reference.",
    ],
  },
  {
    title: "Plant Digitization/BIM",
    desc: "Plant Digitization is a process of converting actual factory/plant/asset into a detailed digital 3D model. The Digital 3D model is used for planning and design and also for virtual reality experiences that permit workers to interact with digital models of the factory or plant in a safe and immersive way, which can help to enhance training and maintenance.",
    images: [PD1, PD2, PD3],
    importance: "Why Plant Digitization/BIM is required?",
    importanceList: [
      "Lack of drawings.",
      "No modification documents are available.",
      "Modernization and re-modelling of existing buildings.",
      "Relocation of the whole plant.",
      "Investigation of the hazardous plant",
      "Clash Detection",
      "Mechanical, Electrical & Plumbing (MEP)",
    ],
    adavantages: [
      "The benefits of 3d laser scanning are high accuracy, long-range, and rapid data acquisition.",
      "A cost-effective approach for creating an as-built of the facility",
      "Reduce production schedule, minimize risks, and enhance operational efficiency",
      "Calculate the most complex industrial building accurately and quickly and it is more accurate than traditional methods",
      "3D Laser scanning permits us to get measurements in hard-to-reach areas without putting someone in unsafe conditions",
      "BIM models will exponentially facilitate maintenance processes and will cut downtime and costs",
    ],
  },
  {
    title: "Heritage Digitisation",
    desc: "Over the past few years, 3D scanning has become an important and contactless approach to the documentation of cultural heritage and its long-term preservation. High-resolution 3D scanning of sites, monuments, and artifacts allows us to monitor, study, and understand our cultural history. The 3D laser scanner technology has provided advanced technology for documenting heritage sites precisely without damaging their original structure or environment. The 3D laser scanners can accurately create the same raw data as the site by using a digital camera and advanced laser scanners which create a point cloud of scanned heritage or excavation sites and create accurate 3D data. The point cloud can be documented as CAD models easily.ss",
    images: [],
    importance: "Why Heritage Digitisation is required?",
    importanceList: [
      "It helps archeologists and architects to study and modify the existing heritage structure",
      "By Digitizing Heritage Sites, Visitors can gain increased access to Fragile and endangered sites.",
      "Digitized Heritage Sites Will Be Accessible to the Next generation In Their current state",
      "Digitized Sites Can Be Utilized For Promoting Digital Tourism Which Can Be Accessible From Any Part Of The World.",
      "These Digital Data Also Give the Site Caretakers Better Tools to Preserve These Sites Preventing Their Loss.",
      "Digital Data Used To Create Replicas.",
      "Digital Data helps in the restoration of historical sites which are in danger, be that from armed conflicts, natural disasters, or simply time erosion.",
    ],
    adavantages: [
      "Governance and reliability",
      "Improved document security",
      "Faster access to information",
      "Remote access to data",
      "Provide real-time data and analytics",
      "Virtual Museum",
      "Capturing more details",
    ],
  },
  {
    title: "Artifacts Digitisation",
    desc: "Digital 3D data of medium and small-size archaeological artifacts is intended to support the elaboration of a virtual and interactive exhibition environment, and also to provide a scientific archive of highly accurate models for specialists. Digitized data helps conservators to examine scientifically the object without any direct contact",
    images: [AD1, AD2, AD3, AD4, AD5],
    importance: "Why artifacts Digitisation is required?",
    importanceList: [
      "Archaeologists or museum curators can improve the conservation of their collections",
      "Analyse competitors’ products for performance and benchmarking.",
      "Digitized Data helps to repair or duplicate the most damaged items by using 3D printing and 3D scanning",
      "It helps to store fragile, rare, and ancient historical artifacts virtually",
      "Digitized Data helps to study, restore or simply archive them with much more details than traditional 2D pictures.t",
    ],
    adavantages: [
      "Governance and reliability",
      "Improved document security",
      "Faster access to information",
      "Remote access to data",
      "Provide real-time data and analytics",
      "Virtual Museum",
      "Capturing more details",
      "Restoring and replicating historical artifacts",
    ],
  },
];

const Engineering = () => {
  return (
    <>
      {services.map((service, index) => (
        <Container
          key={index}
          maxWidth={false}
          disableGutters
          sx={{
            pl: 10,
            pr: 10,
            pb: 5,
            // pt: 5,

            // textAlign: "justify",
          }}>
          <Box>
            <Typography sx={{ mb: 2, mt: 2 }} variant="h4" textAlign={"center"}>
              {service.title}
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "20px", sm: "24px", md: "28px" },
                fontWeight: 400,
                mb: 5,
              }}
              variant="h5">
              {service.desc}
            </Typography>
          </Box>
          {service.images.length > 0 && (
            <Box
              sx={{
                background:
                  "linear-gradient(180deg, #FFFFFF 0%, #A6BAEA 44.79%, #FFFFFF 100%)",
                display: "grid",
                gridTemplateColumns: "repeat(4,1fr)",
                justifyItems: "center",
                pl: 10,
                pr: 10,
                pt: 4,
                pb: 4,
                mb: 5,
                rowGap: 5,
              }}
              s>
              {service.images.map((image, indImg) => (
                <img
                  className="DigitisationImageItem"
                  key={indImg}
                  src={image}
                  alt=""
                />
              ))}
            </Box>
          )}

          <Box>
            <Typography variant="h5">{service.importance}</Typography>
            <ul>
              {service.importanceList.map((importanceItem, indexInner) => (
                <li key={indexInner} style={{ fontSize: "20px" }}>
                  {importanceItem}
                </li>
              ))}
            </ul>
          </Box>
          <Box>
            <Typography variant="h5">Advantages:</Typography>
            <ul>
              {service.adavantages.map((advantage, indexAdv) => (
                <li key={indexAdv} style={{ fontSize: "20px" }} s>
                  {advantage}
                </li>
              ))}
            </ul>
          </Box>
          <Divider></Divider>
        </Container>
      ))}
    </>
  );
};

export default Engineering;
