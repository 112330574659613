import React from "react";
import { Box, Typography } from "@mui/material";
import Banner from "../../assets/digitisation/Banner.png";
const Banners = () => {
  return (
    <>
      <Box
        component="img"
        sx={{
          display: "block",
          overflow: "hidden",
          width: "100vw",
          maxWidth: "100%",
          mb: 10,
        }}
        src={Banner}
        alt={"Banner"}
      />
      <Box
        sx={{
          position: "relative",
          mt: "-40vw",
          width: "40%",
          p: 2,
          ml: 10,
          mb: "30vw",
          // backgroundColor: "#00000075",
          // borderRadius: "20px 0px 20px 0px",
        }}
      >
        <Typography
          sx={{ mb: 2, color: "#ffffff", fontSize: 80, fontWeight: "bold" }}
        >
          DIGITISATION
        </Typography>
      </Box>
    </>
  );
};

export default Banners;
