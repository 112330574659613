import React, { useEffect } from "react";
import Banners from "../components/contact-us/Banners";
import Form from "../components/contact-us/Form";

const ContactUs = () => {
  useEffect(() => {
    document.title = "ams3d: Contact Us";
  }, []);

  return (
    <>
      <Banners />
      <Form />
    </>
  );
};

export default ContactUs;
