import React, { useEffect } from "react";
import Banners from "../components/metrology-sevices/Banners";
import ServiceInfo from "../components/metrology-sevices/ServiceInfo";
import MetroService from "../components/metrology-sevices/MetroService";

import Lg1 from "../assets/metrology-services/Lg1.png";
import Lg2 from "../assets/metrology-services/Lg2.png";
import Lg3 from "../assets/metrology-services/Lg3.png";

import Cg1 from "../assets/metrology-services/Cg1.png";
import Cg2 from "../assets/metrology-services/Cg2.png";
import Cg3 from "../assets/metrology-services/Cg3.png";

import Pg1 from "../assets/metrology-services/Pg1.png";
import Pg2 from "../assets/metrology-services/Pg2.png";
import Pg3 from "../assets/metrology-services/Pg3.png";

import Sg1 from "../assets/metrology-services/Sg1.png";
import Sg2 from "../assets/metrology-services/Sg2.png";
import Sg3 from "../assets/metrology-services/Sg3.png";

import service1 from "../assets/metrology-services/service1.png";
import service2 from "../assets/metrology-services/service2.png";
import service3 from "../assets/metrology-services/service3.png";
import service4 from "../assets/metrology-services/service4.png";

const serivcesList = [
  {
    title: "LASER TRACKER INSPECTION SERVICES",
    descImg: service1,
    description:
      " Laser trackers can be used to measure angles, coordinates, and GD&T over a wide measurement range in less time and with higher accuracythan tape measures, callipers, or micrometres. Many laser trackers are also able to import 3D CAD data for comparing differences with actual targets. Laser Tracker has a level that is accurate to +/- 2arcseconds. There are 3600 arcseconds in one degree, or 1 arcsecond is 0.000278 degrees.",
    advTitle: "Advantages of the Laser Tracker",
    advantageList: [
      "Versatile Mount. The laser tracker can be mounted sideways, upside down,or directly on the part being measured.",
      "Small & Lightweight.",
      "Easy Operation.",
      "Continuous Point Measurement.",
      "Super-Fast Single Point Measuring.",
      "Highest Precision and Accuracy.",
    ],
    photoGalleryList: [Lg1, Lg2, Lg3],
  },
  {
    title: "CMM INSPECTION SERVICES (ONSITE)",
    descImg: service2,
    description:
      "The portable arm's accuracy depends on the operator, the accuracy is quite respectable, in most cases coming in at less than. 002 inch with point repeatability in the 0.0015-inch range. A coordinate measuring machine (CMM) works in much the same way as your finger when it traces map coordinates; its three axes form the machine's coordinate system. Instead of a finger, the CMM uses a probe to measure points on a workpiece. Each point on the workpiece is unique to the machine's coordinate system.",
    advTitle: "Advantages of CMM Inspection",
    advantageList: [
      "Co-ordinate measuring machines are useful for three dimensional measurements.",
      "Precise movements in x, y and z coordinates which can be easily measured and controlled.",
      "Highly precise and reduce inspection time",
      "Maintains consistency in the process",
      "High degree of accuracy can be obtained",
    ],
    photoGalleryList: [Cg1, Cg2, Cg3],
  },
  {
    title: "3D SCANNING SERVICES (ONSITE/OFFSITE)",
    descImg: service3,
    description:
      "3D scanning is the process of analyzing a real-world object or environment to collect three-dimensional data of its shape and possibly its appearance (e.g., colour). The collected data can then be used to construct digital 3D models.Laser triangulation-based 3D scanners use either a laser line or a single laser point to scan across an object. First, the 3D scanner casts its laser onto the object. As the laser light reflects off of the 3D scanned object, its initial trajectory is modified and picked up by a sensor.",
    advTitle: "Advantages of 3D Scanning",
    advantageList: [
      "ACCURACY OF UP TO 0.030 mm (0.0012 in)",
      "RESOLUTION OF UP TO 0.050 mm (0.002 in)",
      "HIGH REPEATABILITY AND TRACEABLE CERTIFICATE.",
      "Independent Built-in photogrammetry",
    ],
    photoGalleryList: [Sg1, Sg2, Sg3],
  },
  {
    title: "3D PRINTING SERVICES",
    descImg: service4,
    description:
      "3D printing uses computer-aided design to create three-dimensional objects through a layering method. Sometimes referred to as additive manufacturing, 3D printing involves layering materials, like plastics, composites or bio-materials to create objects that range in shape, size, rigidity and color. Plastic is still the most popular material used for 3D printing. As the 3D-printing market value increases, the list of what materials can be used also grows. Raw materials such as metal, graphite, and carbon fiber are commonly used for 3D printing, though at-home use is mostly limited to PLA for now.",
    advTitle: "Advantages of 3D Printing",
    advantageList: [
      "Quickly create product models and prototypes",
      "Create three-dimensional objects",
    ],
    photoGalleryList: [Pg1, Pg2, Pg3],
  },
];

const MetrologyServices = () => {
  useEffect(() => {
    document.title = "ams3d: Metrology Services";
  }, []);

  return (
    <>
      <Banners />
      <ServiceInfo />
      {serivcesList.map((serviceListItem, index) => (
        <MetroService service={serviceListItem} key={index} />
      ))}
    </>
  );
};

export default MetrologyServices;
