import { Container, Box, Typography } from "@mui/material";
import React from "react";

import App1 from "../../assets/pmt-products/App1.png";
import App2 from "../../assets/pmt-products/App2.png";
import App3 from "../../assets/pmt-products/App3.png";

import TechFeature from "../../assets/pmt-products/TechFeature.png";

import spec1 from "../../assets/pmt-products/spec1.png";
import spec2 from "../../assets/pmt-products/spec2.png";
import spec3 from "../../assets/pmt-products/spec3.png";
import spec4 from "../../assets/pmt-products/spec4.png";
import spec5 from "../../assets/pmt-products/spec5.png";
import spec6 from "../../assets/pmt-products/spec6.png";
import spec7 from "../../assets/pmt-products/spec7.png";
import spec8 from "../../assets/pmt-products/spec8.png";

const specs = [
  {
    icon: spec1,
    spec: "Working Range : 1.5m - 4.5m",
  },
  {
    icon: spec2,
    spec: "Operating Temperature Range : 0°C - 40°C",
  },
  {
    icon: spec3,
    spec: "Temperature Rate : 3°C/5min",
  },
  {
    icon: spec4,
    spec: "Operating Humidity Range : 0 - 95%, Non-Considensing",
  },
  {
    icon: spec5,
    spec: "Power Supply : Universal Worldwide voltage;100 - 240VAC;50/60Hz",
  },
  {
    icon: spec6,
    spec: "Battery Life : 8-10 Hrs",
  },
  {
    icon: spec7,
    spec: "USB Or Wi - Fi",
  },
  {
    icon: spec8,
    spec: "8.5 Kg",
  },
];

const TechSpecs = () => (
  <Box sx={{ p: 5 }}>
    <Typography variant="h4" textAlign="center">
      Technical Specifications
    </Typography>
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <div>
        {specs.map((spec, index) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10%",
              marginTop: 20,
            }}>
            <img src={spec.icon} alt="icons" width={"5%"} />
            <div>{spec.spec}</div>
          </div>
        ))}
      </div>
    </Box>
  </Box>
);

const Applications = ({ showApplications }) => {
  return (
    <Container disableGutters maxWidth={false}>
      {showApplications && (
        <Box sx={{ p: 5 }}>
          <Typography variant="h4" textAlign="center">
            Applications
          </Typography>
          <Box
            sx={{
              mt: 5,
              display: "flex",
              justifyContent: "space-around",
              flexDirection: { xs: "column", sm: "column", md: "row" },
            }}>
            {[App1, App2, App3].map((srcImg, index) => (
              <img
                src={srcImg}
                alt="tech spec"
                key={index}
                width="30%"
                style={{ borderRadius: 10 }}
              />
            ))}
          </Box>
        </Box>
      )}

      <Box sx={{ p: 5 }}>
        <Typography variant="h4" textAlign="center">
          Technical Features
        </Typography>
        <Box
          sx={{
            mt: 5,
            display: "flex",
            justifyContent: "center",
          }}>
          <img src={TechFeature} alt="tech spec" />
        </Box>
      </Box>
      <TechSpecs />
    </Container>
  );
};

export default Applications;
