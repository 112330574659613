import React from "react";
import { Container, Box, Typography } from "@mui/material";

import Service from "../../assets/product-sales/Services.png";

// const specificationList = [
//   {
//     title: "ZG REIEL SCAN",
//     desc: "",
//     list: [
//       {
//         title: "REIGEL SCAN ELITE",
//         desc: "REIGEL SCAN ELITE RigelScan Elite Easy to Use Blue Laser 3D Scanner with Ultra Fine Scanning from China manufacturer - ZG Technology Co., Ltd. (3d-zg.com)",
//       },
//       {
//         title: "REIGEL SCAN PLUS",
//         desc: "RigelScan Plus Intelligent High Resolution Versatile 3D Scanner from China manufacturer - ZG Technology Co., Ltd. (3d-zg.com)",
//       },
//     ],
//   },
//   {
//     title: "ZG MARVEL SCAN",
//     desc: "MarvelScan Tracker Free Marker Free 3D Laser Scanner with Independent Built-in Photogrammetry from China manufacturer - ZG Technology Co., Ltd. (3d-zg.com)",
//   },
//   {
//     title: "ZG ATLA SCAN",
//     desc: "AtlaScan Professional Industrial Grade High Accuracy 3D Scanner from China manufacturer - ZG Technology Co., Ltd. (3d-zg.com)",
//   },
//   {
//     title: "ZG HYPER SCAN:",
//     desc: "HyperScan Plus Optical Tracking 3D Laser Scanner for Non-contact Measurement Without Markers from China manufacturer - ZG Technology Co., Ltd. (3d-zg.com)",
//   },
// ];

const Services = () => {
  return (
    <Container maxWidth={false}>
      <Box textAlign={"center"}>
        <Typography
          sx={{ textAlign: "center", mt: 4, fontWeight: 700 }}
          variant="h4">
          Our Services
        </Typography>
        <img src={Service} alt="tech spec" width="50%" />
        <Typography
          sx={{ textAlign: "center", mb: 4, mt: 4, fontWeight: 700 }}
          variant="h6">
          “One phone call or a mail and you will get a team of experts working
          behind for you-service is what sets us apart.”
        </Typography>
      </Box>
      {/* {specificationList.map((specification, index) => (
        <Box sx={{ pl: 10, pr: 10, mb: 4 }}>
          <Typography sx={{ mt: 4, fontWeight: 800 }} variant="h6">
            {specification.title}
          </Typography>
          {specification.list && (
            <ol type="a" key={index}>
              {specification.list.map((listItem, index) => (
                <>
                  <li style={{ fontWeight: 700 }}>{listItem.title}</li>
                  <Typography sx={{ textDecoration: "underline" }}>
                    {listItem.desc}
                  </Typography>
                </>
              ))}
            </ol>
          )}
          <Typography sx={{ textDecoration: "underline" }}>{specification.desc}</Typography>
        </Box>
      ))} */}
    </Container>
  );
};

export default Services;
