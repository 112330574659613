import React, { useState } from "react";
import { Container, Typography, Box, Paper, Grid, Link } from "@mui/material";
import { styled } from "@mui/material/styles";
import { PropTypes } from "prop-types";
import news1 from "../../assets/news1.png";
import news11 from "../../assets/news11.png";
import EventDetails from "./EventDetails";

const NewsEventsData = [
  {
    newsImg: news1,
    newsDetailImg: news11,
    newsTitle: "Expo 2023",
    newsDesc: "Glimpses of the Last week Engineering Expo 2023",
  },
  {
    newsImg: news1,
    newsDetailImg: news11,
    newsTitle: "Expo 2023",
    newsDesc: "Glimpses of the Last week Engineering Expo 2023",
  },
  {
    newsImg: news1,
    newsDetailImg: news11,
    newsTitle: "Expo 2023",
    newsDesc: "Glimpses of the Last week Engineering Expo 2023",
  },
  {
    newsImg: news1,
    newsDetailImg: news11,
    newsTitle: "Expo 2023",
    newsDesc: "Glimpses of the Last week Engineering Expo 2023",
  },
];

const ItemGrid = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        bgcolor: (theme) =>
          theme.palette.mode === "dark" ? "#101010" : "#fff",
        color: (theme) =>
          theme.palette.mode === "dark" ? "grey.300" : "grey.800",
        border: "1px solid",
        borderColor: (theme) =>
          theme.palette.mode === "dark" ? "grey.800" : "grey.300",
        p: 1,
        m: 1,
        borderRadius: 2,
        fontSize: "0.875rem",
        fontWeight: "700",
        ...sx,
      }}
      {...other}
    />
  );
}

Item.propTypes = {
  /**
   * The system prop that allows defining system overrides as well as additional CSS styles.
   */
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};

const NewsEvents = () => {
  const [newsData, setNewsData] = useState(NewsEventsData[0]);

  return (
    <Container maxWidth={false} sx={{ mt: 2 }}>
      <Typography sx={{ textAlign: "center" }} variant="h4">
        News & Events
      </Typography>
      <Container
        sx={{
          display: "flex",
          marginTop: "3%",
          flexDirection: {
            xs: "column-reverse",
            sm: "column-reverse",
            md: "row",
          },
        }}
        maxWidth={false}>
        <div style={{ flexBasis: "40%", padding: "5px" }}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              {NewsEventsData.map((news, index) => (
                <Grid item xs={12} md={12} key={index}>
                  <ItemGrid>
                    <div style={{ display: "flex", padding: "2px" }}>
                      <div>
                        <img src={news.newsImg} width={"110px"} alt=""></img>
                      </div>
                      <div
                        style={{
                          textAlign: "left",
                          paddingLeft: "15px",
                          paddingRight: "15px",
                        }}>
                        <Typography color={"#000000"} fontWeight={500}>
                          {news.newsTitle}
                        </Typography>
                        <Typography>
                          <Typography
                            color={"#000000"}
                            fontWeight={300}
                            sx={{ mb: 4 }}>
                            {news.newsDesc}
                          </Typography>
                          <Link
                            onClick={() =>
                              setNewsData((prevState) => {
                                return {
                                  ...prevState,
                                  newsDesc: news.newsDesc,
                                  newsTitle: news.newsTitle,
                                  newsImg: news.newsImg,
                                  newsDetailImg: news.newsDetailImg,
                                };
                              })
                            }
                            fontWeight={400}>
                            Read More
                          </Link>
                        </Typography>
                      </div>
                    </div>
                  </ItemGrid>
                </Grid>
              ))}
            </Grid>
          </Box>
        </div>
        <div style={{ flexBasis: "60%", padding: "10px" }}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <div style={{ paddingLeft: "5px" }}>
                  <Typography
                    sx={{ textAlign: "left", color: "#000000" }}
                    variant="h6">
                    {newsData.newsTitle}
                  </Typography>
                  {/* <Typography sx={{ textAlign: "left" }}>
                    Duis rutrum arcu eget orci inibus, in ultricies ligula
                    dictum.
                  </Typography> */}
                </div>
                <div>
                  <img src={newsData.newsDetailImg} alt={"NewsDetails"}></img>
                </div>
                <div style={{ paddingLeft: "5px" }}>
                  <Typography sx={{ textAlign: "left" }}>
                    {newsData.newsDesc}
                  </Typography>
                  {/* <Typography sx={{ textAlign: "left" }}>
                    Ut sodales eleifend elit, vel vulputate purus pulvinar id.
                    Phasellus convallis leo vitae massa imperdiet fermentum sed
                    nec turpis. Cras euismod vel augue ac mollis
                  </Typography>
                  <Typography sx={{ textAlign: "left" }}>
                    Praesent ornare diam sit amet maximus interdum. Sed
                    condimentum, lectus in pellentesque molestie, quam velit
                    aliquam nisl, quis scelerisque tellus lectus et tellus.
                    Fusce volutpat hendrerit finibus.
                  </Typography> */}
                </div>
                {/* </ItemGrid> */}
              </Grid>
            </Grid>
          </Box>
          <EventDetails content />
        </div>
      </Container>
    </Container>
  );
};

export default NewsEvents;
