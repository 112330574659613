import React, { useEffect } from "react";
import ComingSoon from "../components/common/ComingSoon";

const Careers = () => {
  useEffect(() => {
    document.title = "ams3d: Careers";
  }, []);
  return (
    <>
      <ComingSoon />
    </>
  );
};

export default Careers;
