import { Container, Typography, Box } from "@mui/material";
import React from "react";

import products from "../../assets/accessories/Products.png";

const Description = () => {
  return (
    <Container maxWidth={false} disableGutters>
      <Box
        sx={{
          p: "5%",
          background:
            "linear-gradient(180deg, #FFFFFF 0%, #D9D9D9 44.79%, #FFFFFF 100%)",
        }}>
        <Typography variant="h6">
          <span style={{ fontWeight: "bold" }}>
            Portable Arm Ball Probes and Styli for Portable CMMs:
          </span>
          &nbsp; We offer a variety of probes from direct OEM replacements to
          our own proprietary Carbide Extended Probes and Carbon Fibre Extended
          Probes. We use the best quality materials we can find to ensure that
          the products you receive perform as well or better than the OEM
          products you are used to.
        </Typography>
        <Typography variant="h6" sx={{ mt: 5 }}>
          <span style={{ fontWeight: "bold" }}>
            Laser Tracker Probes and Tooling:
          </span>
          &nbsp; If you are looking for standard replacement SMRs, and Tooling
          you have come to the right place. Also check out our unique line of
          Laser Tracker Tooling, our Bullseye™ series tooling will ensure that
          you “Never Miss Your Mark” ™
        </Typography>
        <Typography variant="h6" sx={{ mt: 5 }}>
          <span style={{ fontWeight: "bold" }}>
            Tripods, Mounts, and Accessories:
          </span>
          &nbsp; Whether you are in the market for Portable Tripods, Rolling
          Stands, Magnetic Mounts or other related accessories we have you
          covered. Our line also includes Calibration Cones, Leap Frog (Move
          Device) Spheres and Cones, and accessories for 3D Laser Line Probes
          and 3D Laser Scanners.
        </Typography>
      </Box>
      <Box
        sx={{
          pl: 10,
          pr: 10,
          mt: -7,
          textAlign: "center",
          display: { xs: "none", md: "block" },
        }}>
        <img src={products} alt="" width="80%" />
      </Box>
    </Container>
  );
};

export default Description;
