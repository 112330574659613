import React from "react";
import { Container, Box, Typography, Link } from "@mui/material";

import cert1 from "../../assets/product-sales/cert1.png";
import cert2 from "../../assets/product-sales/cert2.png";
import cert3 from "../../assets/product-sales/cert3.png";
import cert4 from "../../assets/product-sales/cert4.png";
import cert5 from "../../assets/product-sales/cert5.png";
import cert6 from "../../assets/product-sales/cert7.png";
import cert7 from "../../assets/product-sales/cert8.png";
import cert8 from "../../assets/product-sales/cert1.png";

const Awards = () => {
  return (
    <Container maxWidth={false} disableGutters>
      <Typography sx={{ textAlign: "center", mb: 4, mt: 4 }} variant="h4">
        Awards & Cetifications
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: 5,
        }}>
        <Box sx={{ display: "flex", gap: 8 }}>
          <img src={cert1} alt="cert" />
          <img src={cert2} alt="cert" />
          <img src={cert3} alt="cert" />
          <img src={cert4} alt="cert" />
        </Box>
        <Box sx={{ display: "flex", gap: 8 }}>
          <img src={cert5} alt="cert" />
          <img src={cert6} alt="cert" />
          <img src={cert7} alt="cert" />
          <img src={cert8} alt="cert" />
        </Box>
      </Box>
    </Container>
  );
};

export default Awards;
