import React, { useEffect } from "react";
import ComingSoon from "../components/common/ComingSoon";
import Banners from "../components/digitisation/Banners";
import Engineering from "../components/digitisation/Engineering";

const Digitisation = () => {
  useEffect(() => {
    document.title = "ams3d: Digitisation";
  }, []);
  return (
    <>
      {/* <ComingSoon /> */}
      <Banners />
      <Engineering />
    </>
  );
};

export default Digitisation;
