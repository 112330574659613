import React from "react";
import classes from "../styles/Footer.module.css";
import { Divider, Link, Typography } from "@mui/material";

import footerAMSLogo from "../../assets/FooterAMS3d.png";

// Logos
import fb from "../../assets/fb.png";
import wa from "../../assets/wa.png";
import insta from "../../assets/insta.png";
import linkedin from "../../assets/in.png";
import yt from "../../assets/yt.png";

// Icons
import { WifiCalling, Drafts } from "@mui/icons-material";

// helper functions
import downloadAttachment from "../utils/downloadAttachment";

const logos = [
  {
    name: "fb",
    path: fb,
    onClickLink: "https://www.facebook.com",
  },
  {
    name: "in",
    path: linkedin,
    onClickLink: "https://www.linkedin.com/company/accu-measuring-solutions/",
  },
  {
    name: "yt",
    path: yt,
    onClickLink: "https://www.youtube.com/@ams3dmetrology716",
  },
  {
    name: "insta",
    path: insta,
    onClickLink: "https://www.instagram.com",
  },
  {
    name: "wa",
    path: wa,
    onClickLink: "https://web.whatsapp.com/",
  },
];

const Footer = () => {
  const onClickImg = (link) => {
    window.open(link, "_blank");
  };

  // const downloadAttachment = (pdfUrlLink) => {
  //   const pdfUrl = pdfUrlLink;
  //   const link = document.createElement("a");
  //   link.href = pdfUrl;
  //   link.download = "AMS3D_PMT_Brochure.pdf"; // specify the filename
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };
  return (
    <div className={classes.footer}>
      <div>
        <div style={{ textAlign: "center" }} sx={{ cursor: "pointer" }}>
          <img src={footerAMSLogo} alt="footerlogo" width="17%" />
        </div>

        <Divider
          variant="center"
          sx={{ bgcolor: "#ffffff", mt: 1, ml: 10, mr: 10, height: "3px" }}
        />
        <div className={classes.footerContent}>
          <div className={classes.footerLogos}>
            {logos.map((logo, index) => (
              <img
                src={logo.path}
                alt={logo.name}
                key={index}
                width="58px"
                onClick={() => onClickImg(logo.onClickLink)}
              />
            ))}
          </div>
          <div className={classes.footerContact}>
            <Typography>
              <WifiCalling />
              <span>+91-8956045770</span>
            </Typography>
            <Typography>
              <Drafts />
              <span>insidesale@ams3d.in</span>
            </Typography>
            <Typography>
              HQ Address: Office No - 315, Gold Field Mall
            </Typography>
            <Typography>
              Sector - 11 PCNTDA, Bhosari, Pune- 412105, Maharashtra
            </Typography>
          </div>
        </div>
        <div style={{ textAlign: "center", marginTop: "4%" }}>
          <Link
            href="/"
            sx={{
              mr: 8,
              textDecoration: "none",
              fontSize: "20px",
              fontWeight: 500,
              color: "#ffffff",
            }}>
            Home
          </Link>
          <Link
            onClick={() => downloadAttachment("Brochure.jpg")}
            sx={{
              textDecoration: "none",
              fontSize: "20px",
              fontWeight: 500,
              color: "#ffffff",
              cursor: "pointer",
            }}>
            Download Brochure
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
