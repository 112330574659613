import { Container } from "@mui/material";
import React, { useState } from "react";

const VideoInfo = () => {
  const [isAutoPlay, setAutoPlay] = useState(0);
  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{ bgcolor: "#cccccc" }}
      onMouseEnter={() => setAutoPlay(1)}
      onMouseLeave={() => setAutoPlay(0)}>
      <iframe
        width="100%"
        height="400"
        src={`https://www.youtube.com/embed/JtimwQYDtDk?autoplay=${isAutoPlay}&rel=0`}
        title="AMS3D Office Video"
        frameborder="0"
        allow="autoplay"
        allowfullscreen></iframe>
    </Container>
  );
};

export default VideoInfo;
