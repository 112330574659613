import React from "react";
import { Container, Typography, Box } from "@mui/material";

import c1 from "../../assets/c1.png";
import c2 from "../../assets/c2.png";
import c3 from "../../assets/c3.png";
import c4 from "../../assets/c4.png";
import c5 from "../../assets/c5.png";
import c6 from "../../assets/c6.png";
import c7 from "../../assets/c7.png";
import c8 from "../../assets/c8.png";
import c9 from "../../assets/c9.png";
import c10 from "../../assets/c10.png";
import c11 from "../../assets/c11.png";
import c12 from "../../assets/c12.png";
import c13 from "../../assets/c13.png";

import hard1 from "../../assets/hard1.png";
import hard2 from "../../assets/hard2.png";
// import hard3 from "../../assets/hard3.png";

import soft1 from "../../assets/soft1.png";

const OurClients = [
  {
    title: "c1",
    src: c1,
  },
  {
    title: "c2",
    src: c2,
  },
  {
    title: "c3",
    src: c3,
  },
  {
    title: "c4",
    src: c4,
  },
  {
    title: "c5",
    src: c5,
  },
  {
    title: "c6",
    src: c6,
  },
  {
    title: "c7",
    src: c7,
  },
  {
    title: "c8",
    src: c8,
  },
  {
    title: "c9",
    src: c9,
  },
  {
    title: "c10",
    src: c10,
  },
  {
    title: "c11",
    src: c11,
  },
  {
    title: "c12",
    src: c12,
  },
  {
    title: "c13",
    src: c13,
  },
];

const hardware = [
  {
    title: "hard1",
    src: hard1,
  },
  {
    title: "hard2",
    src: hard2,
  },
  // {
  //   title: "hard3",
  //   src: hard3,
  // },
];

const software = [
  {
    title: "soft1",
    src: soft1,
  },
];

const Clients = () => {
  return (
    <Container
      sx={{
        background:
          "linear-gradient(180deg, #8DAFBB 0%, rgba(141, 175, 187, 0) 100%)",
        mb: 4,
        pt: 4,
        pb: 4,
        mt: 4,
      }}
      maxWidth={false}
      disableGutters>
      <Typography variant="h4" textAlign={"center"}>
        Our Clients
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "1%",
          mt: 6,
        }}>
        {OurClients.map((client, index) => (
          <img src={client.src} alt={client.title} key={index} />
        ))}
      </Box>
      <Box
        sx={{
          display: "grid",
          // alignItems: "center",
          justifyItems: "center",
          gridTemplateColumns: "1fr 1fr",
          columnGap: "5%",
          textAlign: "center",
          mt: 5,
        }}>
        <Box>
          <Typography variant="h5">HardWare Partners</Typography>
          {hardware.map((client, index) => (
            <img src={client.src} alt={client.title} key={index} />
          ))}
        </Box>
        <Box>
          <Typography variant="h5">Software Partners</Typography>
          {software.map((client, index) => (
            <img src={client.src} alt={client.title} key={index} />
          ))}
        </Box>
      </Box>
    </Container>
  );
};

export default Clients;
