import React from "react";
import { Box } from "@mui/material";
import ComingSoonImg from "../../assets/ComimgSoon.jpg";

const ComingSoon = () => {
  return (
    <Box
      src={ComingSoonImg}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "60vw",
        overflow: "hidden",
        maxWidth: "100%",
      }}>
      <img src={ComingSoonImg} alt="Coming Soon" width="50%" />
    </Box>
  );
};

export default ComingSoon;
