import React from "react";

import { Container, Box, Typography } from "@mui/material";

import prod1 from "../../assets/accessories/prod1.png";
// import prod2 from "../../assets/accessories/prod2.png";
// import prod3 from "../../assets/accessories/prod3.png";
import prod4 from "../../assets/accessories/prod4.png";
import prod5 from "../../assets/accessories/prod5.png";
import prod41 from "../../assets/accessories/prod41.png";
import prod51 from "../../assets/accessories/prod51.png";
import prod6 from "../../assets/accessories/prod6.png";
import prod7 from "../../assets/accessories/prod7.png";
import prod8 from "../../assets/accessories/prod8.png";
import prod9 from "../../assets/accessories/prod9.png";
import prod10 from "../../assets/accessories/prod10.png";
import prod11 from "../../assets/accessories/prod11.png";
import prod12 from "../../assets/accessories/prod12.png";
import prod13 from "../../assets/accessories/prod13.png";
import prod121 from "../../assets/accessories/prod121.png";
import prod131 from "../../assets/accessories/prod131.png";
import prod14 from "../../assets/accessories/prod14.png";
import prod15 from "../../assets/accessories/prod15.png";
import prod16 from "../../assets/accessories/prod16.png";

const products = [
  {
    title: "SMR's",
    backgroundColor:
      "linear-gradient(180deg, #C6DDF4 0%, rgba(217, 217, 217, 0) 100%)",
    productsList: [
      {
        name: "",
        img: prod1,
        desc: `SMR stands for Spherically Mounted Retroreflector, which really means a corner cube optic centered in a Ball.
        Other common names for SMR include TMR, CCR, Ball Retro-Reflector, BMR, Retro Reflector, and Ball Corner Cube.
        <ul>
          <li>1.5” & 0.5” Diameter</li>
          <li>SUS-440C Steel</li>
          <li>55m Working Ra</li>
        </ul>`,
      },
      // {
      //   name: "Break PROOF SMR",
      //   img: prod2,
      //   desc: `LTBP-A-Z-SO-SA 1.5" Laser Tracker Ball Probe Solid Standard AccuracyLTBP-A-Z-RS-MA 1.5 Laser Tracker Ball Probe Rhino Series Tough Probe - Mid Accuracy`,
      // },
      // {
      //   name: "GLASS ELEMENT SMR",
      //   img: prod3,
      //   desc: "LTBP-A-Z-RS-MA 1.5 Laser Tracker Ball Probe Rhino Series Tough Probe - Mid Accuracy",
      // },
    ],
  },
  {
    title: "CMM Accessories",
    backgroundColor:
      "linear-gradient(180deg, #C6DDF4 0%, rgba(217, 217, 217, 0) 100%)",
    productsList: [
      {
        name: "Probe",
        img: prod12,
        desc: `Zircon Ball Probe With Various Ball Dia For All Types Of Portable CMM's.`,
      },
      {
        name: "Probe Extension Kit",
        img: prod13,
        desc: `Two 3" straight; 4" straight extension; 60-degree angle; 90-degree angle; 3mm extended ball probe; 6mm extended ball probe; 12" wrench and probe adapter`,
      },
      {
        name: "Magnetic Cone",
        img: prod121,
        desc: `A single cone with an adjustable magnetic base is used for the Leapfrog command. This will increase the measuring volume of the Arm.`,
      },
      {
        name: "Magnetic Sphere",
        img: prod131,
        desc: `A single sphere with an adjustable magnetic base is used for the Leapfrog command. This will increase the measuring volume of the Arm`,
      },
    ],
  },
  {
    title: "SMR ADAPTORS",
    backgroundColor:
      "linear-gradient(180deg, #C6DDF4 0%, rgba(217, 217, 217, 0) 100%)",
    productsList: [
      {
        name: "DRFIT NEST ADAPTORS",
        img: prod6,
        desc: `1.5” Ball Probe Magnetic Drift Nest`,
      },
      {
        name: "SMR Handle",
        img: prod7,
        desc: `Magnetic Handle For 0.5” SMR's`,
      },
      {
        name: "PIN NEST ADAPTORS",
        img: prod8,
        desc: `0.5” / 1.5 “ Ball Probe Nest With Various Shank Dia`,
      },
    ],
  },
  {
    title: "MOUNTS",
    backgroundColor:
      "linear-gradient(180deg, #C6DDF4 0%, rgba(217, 217, 217, 0) 100%)",
    productsList: [
      {
        name: "Tripod Mount",
        img: prod4,
        desc: `Heavy-Duty Portable Tripod For Portable CMMs With Universal 3-1/2” Threaded Ring`,
      },
      {
        name: "Magnetic Mount",
        img: prod5,
        desc: `Magnetic Mount For Portable CMMs With Universal 3-1/2” Threaded Ring`,
      },
      {
        name: "Granite Rolling Cart",
        img: prod41,
        desc: `Complete mobile measurement workstation. The granite table, provides a solid work surface for all CMM Arms.`,
      },
      {
        name: "Electric Vacuum Clamping Plate",
        img: prod51,
        desc: `Battery-powered vacuum mount for the CMM Arms. Specially designed to quickly, easily, and       rigidly mount to granite surfaces constantly without degradation in accuracy`,
      },
    ],
  },
  {
    title: "MAGNETIC MOUNT",
    backgroundColor:
      "linear-gradient(180deg, #C6DDF4 0%, rgba(217, 217, 217, 0) 100%)",
    productsList: [
      {
        name: `Magnetic Mount for Portable CMM with 3 1/2" threaded ring`,
        img: prod9,
        desc: ``,
      },
      {
        name: `MOUNT-MAG-ECO Economic Magnetic Mount for Portable CMM with 3 1/2" threaded ring`,
        img: prod10,
        desc: ``,
      },
    ],
  },
  {
    title: "LASER TRACKER TOOLKIT",
    backgroundColor:
      "linear-gradient(180deg, #C6DDF4 0%, rgba(217, 217, 217, 0) 100%)",
    productsList: [
      {
        name: "",
        img: prod11,
        desc: `Bulls Eye Laser Tracker Tooling Kit - Combo - Standard
        Kit includes:<br>
        For 1.5″ SMR:<br>
        (20) 1.5″ Anodized Aluminium Monuments<br>
        (1) Ball Probe Seat 1/8″ Pin - 1″ Offset<br>
        (1) Ball Probe Seat 1/4″ Pin - 1″ Offset<br>
        (1) Ball Probe Seat 3/8″ Pin - 1″ Offset<br>
        (1) Ball Probe Seat 1/2″ Pin - 1″ Offset<br>
        (1) Flat Ball Probe Seat - 1″ Offset<br>
        (1) Centre Punch Seat - 1″ Offset (Includes Punch Rod)<br>
        (1) Edge Ball Probe Seat - 1″ Offset<br>
        (1) 10″ Remote Point Bar<br>
        (1) Sharp Point<br>
        (1) Cone<br>
        (1) Pole Adapter<br><br>
        Room for: 1.5” SMR's<br>
        Room for additional 1.5” Nests<br>
        For 0.5″ SMR:<br>
        (20) 0.5″ Anodized Aluminium Monuments<br>
        (1) Ball Probe Seat 1/8″ Pin - 1/2″ Offset<br>
        (1) Ball Probe Seat 1/4″ Pin - 1/2″ Offset<br>
        (1) Ball Probe Seat 3/8″ Pin - 1/2″ Offset<br>
        (1) Ball Probe Seat 1/2″ Pin - 1/2″ Offset (Big Body Design)<br>
        (1) Flat Ball Probe Seat - 1/2″ Offset (BT-C-Z-BPS-X-x5-Y)<br>
        (1) Edge Ball Probe Seat, 1/2″ Offset (BT-C-Z-E-x5-Y)<br>
        (1) 1/2″ SMR Magnetic Handle (BT-C-Z-BPH-Y)<br>
        Room for 0.5” SMR's<br>
        Room for additional 0.5” Nests<br>
        Qty 1: Hard-Sided Case and Foam Insert to Hold all Tooling Items`,
      },
    ],
  },

  {
    title: "3D SCANNER REFLECTIVE MARKERS",
    backgroundColor:
      "linear-gradient(180deg, #C6DDF4 0%, rgba(217, 217, 217, 0) 100%)",
    productsList: [
      {
        name: "Normal Reflective Markers 6mm/3mm",
        img: prod14,
        desc: `Uncoded Magnetic Ball Markers are Reusable by 3D scanners and photogrammetry systems to generate 3D coordinates for more accurate inspections and as reference for orientating measurement systems in 3D space.<br>
        Our retro-reflective targets are high precision cut points. This guarantees ideal removability from the sheet and absolute concentricity.
        Our dots are uniquely coated for optimum removability from the component.
        <ul>
        <li>High precision cut</li>
        <li>Highly reflective - also from low angles</li>
        <li>Ideal removability</li>
        <li>Round on sheet</li>
        <li>High quality box package</li>
        <li>Developed by print and scanning experts</li>
        <ul>`,
      },
      {
        name: "Magnetic Ball Cubes 6mm",
        img: prod15,
        desc: `Uncoded Magnetic Ball Markers are Reusable by 3D scanners and photogrammetry systems to generate 3D coordinates for more accurate inspections and as reference for orientating measurement systems in 3D space.<br>
        Our retro-reflective targets are high precision cut points. This guarantees ideal removability from the sheet and absolute concentricity.
        Our dots are uniquely coated for optimum removability from the component.
        <ul>
        <li>High precision cut</li>
        <li>Highly reflective - also from low angles</li>
        <li>Ideal removability</li>
        <li>Round on sheet</li>
        <li>High quality box package</li>
        <li>Developed by print and scanning experts</li>
        <ul>`,
      },
      {
        name: "Magnetic Reflective Markers 6mm/3mm",
        img: prod16,
        desc: `Uncoded Magnetic Ball Markers are Reusable by 3D scanners and photogrammetry systems to generate 3D coordinates for more accurate inspections and as reference for orientating measurement systems in 3D space.<br>
        Our retro-reflective targets are high precision cut points. This guarantees ideal removability from the sheet and absolute concentricity.
        Our dots are uniquely coated for optimum removability from the component.
        <ul>
        <li>High precision cut</li>
        <li>Highly reflective - also from low angles</li>
        <li>Ideal removability</li>
        <li>Round on sheet</li>
        <li>High quality box package</li>
        <li>Developed by print and scanning experts</li>
        <ul>`,
      },
    ],
  },
];

const ProductImage = ({ name, img, odd }) => (
  <Box sx={{ flexBasis: 0, flexGrow: 1, textAlign: "center" }}>
    <Typography sx={{ mb: 3, fontWeight: 600 }} variant="h5">
      {name}
    </Typography>
    <img
      src={img}
      alt={"facility"}
      width="80%"
      style={{
        borderRadius: "10px",
        boxShadow: "0px 4px 10px 0px #FFFFFF80",
        textAlign: odd && "right",
      }}></img>
  </Box>
);

const ProductContent = ({ desc }) => (
  <Box sx={{ flexBasis: 0, flexGrow: 2 }}>
    <Typography
      sx={{
        fontWeight: 400,
        fontSize: { xs: "18px", sm: "20px", md: "24px" },
      }}
      dangerouslySetInnerHTML={{ __html: desc }}
    />
  </Box>
);

const ProductList = ({ data }) => {
  return (
    <Container sx={{ mt: 4 }} maxWidth={false} disableGutters>
      {data.map((ele, index) =>
        index % 2 === 0 ? (
          <Container
            maxWidth={false}
            disableGutters
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "column", md: "row" },
              gap: "3%",
              flexWrap: "wrap",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
            key={index}>
            <ProductImage {...ele} />
            <ProductContent {...ele} odd={true} />
          </Container>
        ) : (
          <Container
            maxWidth={false}
            disableGutters
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column-reverse",
                sm: "column-rverse",
                md: "row",
              },
              gap: "3%",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
            key={index}>
            <ProductContent {...ele} />
            <ProductImage {...ele} odd={true} />
          </Container>
        )
      )}
    </Container>
  );
};

const ProdDesc = () => {
  return (
    <Container sx={{ p: 10 }} maxWidth={false} disableGutters>
      {products.map((product, index) => (
        <Box
          sx={{
            p: 5,
            mb: 10,
            background: product.backgroundColor,
            borderRadius: "30px 0px 30px 0px",
          }}
          key={index}>
          <Typography variant="h4" sx={{ fontWeight: 700, color: "#000" }}>
            {product.title}
          </Typography>
          <ProductList data={product.productsList} />
        </Box>
      ))}
    </Container>
  );
};

export default ProdDesc;
