import { Container, Typography, Box } from "@mui/material";
import React from "react";
import Ind1 from "../../assets/Ind1.png";
import Ind2 from "../../assets/Ind2.png";
import Ind3 from "../../assets/Ind3.png";
import Ind4 from "../../assets/Ind4.png";
import Ind5 from "../../assets/Ind5.png";
import Ind6 from "../../assets/Ind6.png";
import Ind7 from "../../assets/Ind7.png";

const ServeIndustries = [
  {
    title: "Ind3",
    src: Ind3,
    size: "63px",
  },
  {
    title: "Ind2",
    src: Ind2,
    size: "244px",
  },
  {
    title: "Ind1",
    src: Ind1,
    size: "218px",
  },

  {
    title: "Ind4",
    src: Ind4,
    size: "138px",
  },
  {
    title: "Ind5",
    src: Ind5,
    size: "189px",
  },
  {
    title: "Ind6",
    src: Ind6,
    size: "57px",
  },
  {
    title: "Ind7",
    src: Ind7,
    size: "109px",
  },
];

const IndustryServe = () => {
  return (
    <Container
      sx={{
        background:
          "linear-gradient(180deg, #8DAFBB 0%, rgba(141, 175, 187, 0) 100%)",
        mb: 4,
        pt: 4,
        pb: 4,
      }}
      maxWidth={false}
      disableGutters>
      <Typography variant="h4" textAlign={"center"}>
        Industry We Serve
      </Typography>
      <Typography textAlign={"center"} sx={{ mt: 3 }}>
        Aerospace, Automotive, Heavy Engineering, Locomotive, Shipping,
        Civil/Construction & Education
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "20px",
          mt: 6,
        }}>
        {ServeIndustries.map((industry, index) => (
          <img
            src={industry.src}
            alt={industry.title}
            width={industry.size}
            key={index}
          />
        ))}
      </Box>
    </Container>
  );
};

export default IndustryServe;
