import React from "react";
import { Box, Typography } from "@mui/material";
import Banner from "../../assets/metrology-services/Banner.png";
const Banners = () => {
  return (
    <>
      <Box
        component="img"
        sx={{
          display: "block",
          overflow: "hidden",
          width: "100vw",
          maxWidth: "100%",
        }}
        src={Banner}
        alt={"Banner"}
      />
      <Box
        sx={{
          position: "relative",
          mt: "-20vw",
          width: "40%",
          p: 2,
          ml: 4,
          backgroundColor: "#00000075",
          borderRadius: "20px 0px 20px 0px",
        }}>
        <Typography sx={{ mb: 2, color: "#ffffff", fontSize:35 }}>
          3D METROLOGY SERVICES
        </Typography>
        <Typography color="#ffffff">
          3D Metrology is one of the extremely demanding technologies when it
          comes to Manufacturing Industries because Obtaining precise, accurate
          measurement information can mean the difference between success and
          failure when it comes to any products and services.
        </Typography>
      </Box>
    </>
  );
};

export default Banners;
