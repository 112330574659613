import React from "react";
import { Container, Typography, Box } from "@mui/material";

import AccessBanner from "../../assets/accessories/Banner.png";
import BannerLogo from "../../assets/accessories/BannerLogo.png";

const MainContent = () => {
  return (
    <Container
      sx={{
        backgroundImage: "url(" + AccessBanner + ")",
        height: "100%",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        p: "5%",
      }}
      maxWidth={false}
      disableGutters>
      <Box sx={{ width: "50vw", mt: "15%" }}>
        {/* <img src={BannerLogo} alt=""></img> */}
        <Typography
          variant="h3"
          color="#fff"
          sx={{ mb: 2, mt: 2, fontWeight: 600 }}>
          3D Metrology Accessories
        </Typography>
        {/* <Typography variant="h5" color="#fff" sx={{ mb: 2, fontWeight: 200 }}>
          METROLOGY WORKS history dates back over 15 years when we first started
          offering measurement and reverse engineering services with an 8ft Gold
          FARO arm.
        </Typography> */}
        {/* <Typography variant="h5" color="#fff" sx={{ mb: 5, fontWeight: 200 }}>
          The accessories adventure began when we could not find the probes, we
          needed for a contract measurement job. We checked with FARO, and their
          selection of extended probes was fairly limited. With years of
          machining experience and a state-of-the-art machine shop at our
          disposal, the Carbide Extended Probe Series was born. Ever since that
          day, we have continued to expand our product offerings as we see needs
          in the industry and receive feedback from other users. Feedback is
          always welcome! Thank you for visiting our website and giving us the
          opportunity to earn your business.
        </Typography> */}
      </Box>
    </Container>
    // <Box
    //   component="img"
    //   sx={{
    //     backgroundImage: "url(" + AccessBanner + ")",
    //     display: "block",
    //     overflow: "hidden",
    //     width: "100vw",
    //     maxWidth: "100%",
    //   }}
    //   disableGutters
    //   maxWidth={false}
    //   src={AccessBanner}
    //   alt={"AccessBanner"}></Box>
  );
};

export default MainContent;
