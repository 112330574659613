import { Typography, Container, Box } from "@mui/material";
import React from "react";

import SwipeableViews from "react-swipeable-views";
// import { useTheme } from "@mui/material";
import { autoPlay } from "react-swipeable-views-utils";

import fac11 from "../../assets/fac11.png";
import fac12 from "../../assets/fac12.png";
import fac13 from "../../assets/fac13.png";

import fac21 from "../../assets/fac21.png";
import fac22 from "../../assets/fac22.png";
import fac23 from "../../assets/fac23.png";

import fac31 from "../../assets/fac31.png";
import fac32 from "../../assets/fac32.png";
import fac33 from "../../assets/fac33.png";

const data = [
  {
    content: {
      title: "Best In Class Equipments",
      dataContent:
        "Top-class 3D scanner labs are equipped with cutting-edge hardware and software solutions, ensuring highly accurate and detailed scanning results for various applications. These labs feature advanced laser or structured light scanners, paired with sophisticated post-processing software.",
    },
    imageList: [fac11, fac12, fac13],
  },
  {
    content: {
      title: "Remarkable Industry Services",
      dataContent:
        "The best services in Our industry provide highly detailed 3D metrology services, enabling precise digital replication of physical objects   with quality and fidelity. Top notch 3D metrology services offer a wide range of 3D metrologies, including advanced software capabilities, efficient turnaround times.",
    },
    imageList: [fac21, fac22, fac23],
  },
  {
    content: {
      title: "Leading Industry Professionals",
      dataContent:
        "Industry experts in 3D metrologies possess extensive knowledge of advanced technologies, ensuring precise and accuratedata capture. Their expertise enables them to optimize metrologies parameters, troubleshoot technical issues, and provide valuable insights for diverse applications.",
    },
    imageList: [fac31, fac32, fac33],
  },
];

// const FacilityImage = ({ image }) => (
//   <Box sx={{ flexBasis: 0, flexGrow: 1 }}>
//     <img
//       src={image}
//       alt={"facility"}
//       width="100%"
//       style={{
//         borderRadius: "10px",
//         boxShadow: "0px 4px 10px 0px #FFFFFF80",
//       }}></img>
//   </Box>
// );

const AutoPlaySwipeAbleViews = autoPlay(SwipeableViews);

// const springConfig = { duration: "0.3s" };

const FacilityImage = ({ imageList }) => {
  // const theme = useTheme();
  return (
    <Box sx={{ flexBasis: 0, flexGrow: 1 }}>
      <AutoPlaySwipeAbleViews
        axis={"x"}
        direction="incremental"
        // springConfig={springConfig}
      >
        {imageList.map((step, index) => {
          return (
            <img
              src={step}
              alt={"facility"}
              width="100%"
              style={{
                borderRadius: "10px",
                boxShadow: "0px 4px 10px 0px #FFFFFF80",
              }}></img>
          );
        })}
      </AutoPlaySwipeAbleViews>
    </Box>
  );
};

const FacilityContent = ({ content }) => (
  <Box sx={{ flexBasis: 0, flexGrow: 1 }}>
    <Typography
      sx={{
        mb: 2,
        fontWeight: 700,
        fontSize: { xs: "20px", sm: "24px", md: "28px" },
        lineHeight: "45px",
      }}>
      {content.title}
    </Typography>
    <Typography
      sx={{
        fontWeight: 400,
        fontSize: { xs: "20px", sm: "24px", md: "28px" },
      }}>
      {content.dataContent}
    </Typography>
  </Box>
);

const Facility = () => {
  return (
    <Container maxWidth={false}>
      <Typography sx={{ textAlign: "center", mb: 4, mt: 4 }} variant="h4">
        Our Facility
      </Typography>
      {data.map((ele, index) =>
        index % 2 === 0 ? (
          <Container
            maxWidth={false}
            disableGutters
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "column", md: "row" },
              gap: "3%",
              p: 2,
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
            key={index}>
            <FacilityImage {...ele} />
            <FacilityContent {...ele} />
          </Container>
        ) : (
          <Container
            maxWidth={false}
            disableGutters
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column-reverse",
                sm: "column-rverse",
                md: "row",
              },
              gap: "3%",
              p: 2,
              flexGrow: 1,
              justifyContent: "space-between",
            }}
            key={index}>
            <FacilityContent {...ele} /> <FacilityImage {...ele} />
          </Container>
        )
      )}
    </Container>
  );
};

export default Facility;
