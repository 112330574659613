import React, { useEffect, useState } from "react";
import { Box, Button, Typography, MenuItem, Menu } from "@mui/material";

import { NavLink, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import ams3dLogo from "../../assets/AMS_Logo_Navbar.png";
import NavCall from "../../assets/NavCall.png";
import NavEmail from "../../assets/NavEmail.png";

// Drawer
import { Drawer, List, ListItem, ListItemText } from "@mui/material";
import {
  Menu as MenuIcon,
  Close,
  KeyboardArrowDown,
} from "@mui/icons-material";

const NavItems = [
  { title: "Home", to: "/" },
  {
    title: "Products Sales",
    list: [
      {
        listTitle: "PMT Products",
        to: "/pmt-products",
      },
      {
        listTitle: "ZG Products",
        to: "/zg-products",
      },
      {
        listTitle: "Accessories",
        to: "/accessories",
      },
    ],
  },
  { title: "Metrology Service", to: "metrology-services" },
  { title: "Digitisation", to: "digitisation" },
  { title: "Careers", to: "careers" },
  { title: "Contact Us", to: "contact-us" },
];

const Item = (props) => {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        p: 1,
        m: 1,
        bgcolor: (theme) =>
          theme.palette.mode === "dark" ? "#101010" : "grey.100",
        color: (theme) =>
          theme.palette.mode === "dark" ? "grey.300" : "grey.800",
        border: "1px solid",
        borderColor: (theme) =>
          theme.palette.mode === "dark" ? "grey.800" : "grey.300",
        borderRadius: 2,
        fontSize: "0.875rem",
        fontWeight: "700",
        ...sx,
      }}
      {...other}
    />
  );
};

Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};

const Navbar = () => {
  const [isOpenDrawer, setOpenDrawer] = useState(false);
  const [toLink, setToLink] = useState("/null");
  const [width, setWidth] = useState(window.innerWidth);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const navigate = useNavigate();

  const redirectToContact = () => {
    navigate("/contact-us");
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenDrawer(open);
  };

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index, to) => {
    setAnchorEl(null);
    setToLink(to);
    navigate(to);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const list = () => (
    <Box
      sx={{ width: "auto", textAlign: "center" }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}>
      <Button sx={{ float: "right" }}>
        <Close />
      </Button>
      <List>
        {NavItems.map((text, index) => (
          <ListItem key={index} sx={{ textAlign: "center" }}>
            <ListItemText
              primary={
                <NavLink to={text.to} style={{ textDecoration: "none" }}>
                  {({ isActive }) => (
                    <Typography
                      sx={{
                        color: isActive ? "#FF8F50" : "#000000",
                        textTransform: "capitalize",
                        textDecoration: "none",
                      }}>
                      {text.title}
                    </Typography>
                  )}
                </NavLink>
              }
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div
      style={{
        position: "fixed",
        display: "flex",
        flexDirection: "column",
        zIndex: 999,
        width: "100vw",
        marginTop: "5px",
        alignItems: "center",
        justifyContent: "center",
        alignContent: "center",
      }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "10%",
        }}>
        <img
          src={NavEmail}
          alt="Email"
          width="40px"
          onClick={redirectToContact}
          style={{ cursor: "pointer" }}
        />
        <img
          src={NavCall}
          alt="Call"
          width="40px"
          onClick={redirectToContact}
          style={{ cursor: "pointer" }}
        />
      </div>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          border: "2px solid #C7C7C7",
          bgcolor: "#FFFFFF",
          borderRadius: "50px",
          boxShadow: "0px 6px 10px 0px #3C72C14D",
          justifyContent: "space-between",
          width: "80vw",
          margin: "auto",
          mt: 1,
          paddingLeft: 10,
          paddingRight: 10,
        }}
        f>
        <div>
          <img src={ams3dLogo} alt="ams3dLogo" width={"140px"}></img>
        </div>
        <Drawer anchor="top" open={isOpenDrawer} onClose={toggleDrawer(false)}>
          {list()}
        </Drawer>

        {width < 930 ? (
          <div>
            <MenuIcon onClick={toggleDrawer(true)} />
          </div>
        ) : (
          <div>
            <ul type="None">
              {NavItems.map((item, index) =>
                item.list?.length > 0 ? (
                  <li
                    style={{ display: "inline-block", marginLeft: "20px" }}
                    key={index}>
                    <NavLink to={toLink}>
                      {({ isActive }) => (
                        <Button
                          sx={{
                            color: "#000000",
                            "&:hover": {
                              bgcolor: "#FF8F50",
                            },
                            bgcolor: isActive ? "#FF8F50" : "",
                            textTransform: "capitalize",
                          }}
                          onMouseEnter={handleClickListItem}
                          endIcon={<KeyboardArrowDown />}>
                          {item.title}
                        </Button>
                      )}
                    </NavLink>
                    <Menu
                      id="lock-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "lock-button",
                        role: "listbox",
                      }}>
                      {item.list.map((option, index) => (
                        <MenuItem
                          key={option.listTitle}
                          onClick={(event) => {
                            handleMenuItemClick(event, index, option.to);
                          }}>
                          {option.listTitle}
                        </MenuItem>
                      ))}
                    </Menu>
                  </li>
                ) : (
                  <li
                    style={{ display: "inline-block", marginLeft: "20px" }}
                    key={index}>
                    <NavLink to={item.to}>
                      {({ isActive }) => (
                        <Button
                          sx={{
                            color: "#000000",
                            "&:hover": {
                              bgcolor: "#FF8F50",
                            },
                            bgcolor: isActive ? "#FF8F50" : "",
                            textTransform: "capitalize",
                          }}>
                          {item.title}
                        </Button>
                      )}
                    </NavLink>
                  </li>
                )
              )}
            </ul>
          </div>
        )}
      </Box>
    </div>
  );
};

export default Navbar;
