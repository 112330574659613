import React from "react";
import MainContent from "../components/accessories/MainContent";
import Description from "../components/accessories/Description";
import ProdDesc from "../components/accessories/ProdDesc";
import ComingSoon from "../components/common/ComingSoon";

const Accessories = () => {
  return (
    <>
      {/* <ComingSoon /> */}
      <MainContent />
      <Description />
      <ProdDesc />
    </>
  );
};

export default Accessories;
