import "./App.css";
import { useRoutes, useLocation } from "react-router-dom";
import routes from "./routes";

import { useEffect } from "react";
// Components
import Footer from "./components/common/Footer";

import { ThemeProvider, createTheme } from "@mui/material";
import Navbar from "./components/common/Navbar";

const theme = createTheme({
  typography: {
    fontFamily: "Inter",
    // fontSize: 14,
    // fontWeightLight: 300,
    // fontWeightRegular: 400,
    // fontWeightMedium: 500,
  },
});

theme.typography.h4 = {
  fontSize: "1rem",
  "@media (min-width:600px)": {
    fontSize: "1.2rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "2rem",
  },
  fontWeight: 700,
  color: "#667377",
  lineHeight: "50px",
};

const ScrollToTop = (props) => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [location]);

  return <>{props.children}</>;
};

function App() {
  const content = useRoutes(routes());
  return (
    <ThemeProvider theme={theme}>
      <Navbar />
      <ScrollToTop>{content}</ScrollToTop>
      <Footer />
    </ThemeProvider>
  );
}

export default App;
