import {
  Container,
  Box,
  Typography,
  Input,
  Button,
  Modal,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import React, { useRef, useState } from "react";

import emailjs from "@emailjs/browser";

import classes from "./contactus.module.css";

import FormBackground from "../../assets/contact-us/FormBackground.png";

const Form = () => {
  const form = useRef();
  const [showModal, setShowModal] = useState(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_41gtvgv",
        "template_rj1md48",
        form.current,
        "Dpm02LOm7Woz4iBtq"
      )
      .then(
        (result) => {
          // console.log(result.text);
          setShowModal(true);
          form.current.reset();
        },
        (error) => {
          console.log(error.text);
          form.current.reset();
        }
      );
  };
  return (
    // <Container sx={{ p: 30 }} maxWidth={false} disableGutters>
    //   <Typography variant="h4" textAlign="center">
    //     Get In Touch
    //   </Typography>
    //   <Box
    //     sx={{
    //       borderRadius: "20px",
    //       boxShadow: "-10px 10px 20px 0px #00000026",
    //       background:
    //         "linear-gradient(180deg, #F4F4F4 0%, rgba(156, 156, 156, 0) 100%)",
    //       backgroundImage: "url" + FormBackground + ")",
    //     }}>
    //  <form ref={form} onSubmit={sendEmail}>
    //   <label>Name</label>
    //   <input type="text" name="user_name" />
    //   <label>Email</label>
    //   <input type="email" name="user_email" />
    //   <label>Message</label>
    //   <textarea name="message" />
    //   <input type="submit" value="Send" />
    // </form>
    //   </Box>
    //   <p>
    //   <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3780.0181353297135!2d73.82844817596605!3d18.663182164800922!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2b9ebc8d7e975%3A0x5d585f82c81effca!2sAMS3D%20Metrology%20(I)%20PVT.%20LTD.!5e0!3m2!1sen!2sin!4v1690829846867!5m2!1sen!2sin" width="600" height="450" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    //   </p>
    // </Container>
    <>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          marginTop: "-25vw",
          background:
            "linear-gradient(180deg, rgba(0, 40, 143, 0.3) 23.79%, rgba(255, 255, 255, 0) 215.9%)",
        }}>
        <div className={classes.container}>
          <form ref={form} className={classes.contact} onSubmit={sendEmail}>
            <h3>Contact Us</h3>
            <fieldset>
              <input
                placeholder="Your name"
                type="text"
                name="from_name"
                tabIndex="1"
                required
                autoFocus
              />
            </fieldset>
            <fieldset>
              <input
                placeholder="Your Email Address"
                type="email"
                name="reply_to"
                tabIindex="2"
                required
              />
            </fieldset>
            <fieldset>
              <input
                placeholder="Your Phone Number (optional)"
                type="tel"
                tabIndex="3"
              />
            </fieldset>
            <fieldset>
              <textarea
                placeholder="Type your message here...."
                tabIndex="5"
                name="message"
                required></textarea>
            </fieldset>
            <fieldset>
              <button
                name="submit"
                type="submit"
                className="contact-submit"
                value="Send">
                Submit
              </button>
            </fieldset>
          </form>
        </div>

        <div>
          <iframe
            title="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3780.0181353297135!2d73.82844817596605!3d18.663182164800922!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2b9ebc8d7e975%3A0x5d585f82c81effca!2sAMS3D%20Metrology%20(I)%20PVT.%20LTD.!5e0!3m2!1sen!2sin!4v1690829846867!5m2!1sen!2sin"
            width="600"
            height="450"
            style={{ border: "0" }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            textAlign="center">
            <DoneIcon color="green" />
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Your response saved sucessfully, our team will contact you soon..
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default Form;
