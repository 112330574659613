import React from "react";
import { Container, Box, Typography } from "@mui/material";

import Banner from "../../assets/pmt-products/Banner.png";

import PmtBannerCover1 from "../../assets/pmt-products/PmtBannerCover1.png";
import PmtBannerCover2 from "../../assets/pmt-products/PmtBannerCover2.png";

const MainContent = () => {
  return (
    <Container class="container" maxWidth={false} disableGutters>
      <Box>
        <div class="inner">
          <img
            src={Banner}
            alt="banner"
            style={{ width: "100vw", maxWidth: "100%" }}></img>
        </div>
      </Box>
      <Box
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 20,
          mt: "-10vw",
        }}>
        <img src={PmtBannerCover1} alt="banner" width="40%"></img>
        <img src={PmtBannerCover2} alt="banner" width="40%"></img>
      </Box>
      <Box
        sx={{
          p: 10,
          pt: 5,
          mt: "-20vw",
          background:
            "linear-gradient(180deg, rgba(0, 40, 143, 0.3) 23.79%, rgba(255, 255, 255, 0) 215.9%)",
          //   background: "#fff",
        }}>
        <div style={{ position: "relative", marginTop: "20vw" }}>
          <Typography
            textAlign={"left"}
            sx={{ fontWeight: 700, color: "#00799E", letterSpacing: 2 }}
            variant="h5">
            About PMT Technology:
          </Typography>
          <Typography
            variant="h6"
            sx={{ fontSize: "28px", lineHeight: "40px", mt: 2 }}>
            PMT is a high-tech company specialized in measurement technology,
            with full independent R&D and production capability. In the early
            stage, it mainly provides technology output and core technologies
            development and customization for many well-known measurement
            companies in the world, especially in the field of portable
            measurement, optical measurement, encoders, etc. In the course of
            its proactive participation in the global competition, PMT has an
            abundant accumulation of technology and innovation and always been
            following
          </Typography>
          <Typography
            variant="h6"
            sx={{ fontSize: "28px", lineHeight: "40px", mt: 2 }}>
            European standards in product research and development. Oriented by
            advanced technology all the time, PMT inherits German craftsmanship
            and quality control system and goes to great lengths to control
            quality. With the support of Darmstadt University of Technology, PMT
            has achieved certifications by European standards for its products.
            With more than 20 years of experience in the field of metrology,
            especially in portable measurement, PMT has been promoting a sound
            and high-quality development in global measurement with its
            innovative technology and holds several patents. Having contributed
            to a great R&D input, PMT has so far invested a lot of funds in
            long-term cooperation with global advanced Research Institutes and
            put more devotions to independent research and development.
          </Typography>
        </div>
      </Box>
    </Container>
  );
};

export default MainContent;
