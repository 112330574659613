import React from "react";

import { Container, Typography, Box } from "@mui/material";

import Adv1 from "../../assets/product-sales/adv1.png";
import Adv2 from "../../assets/product-sales/adv2.png";
import Adv3 from "../../assets/product-sales/adv3.png";
import Adv4 from "../../assets/product-sales/adv4.png";
import Adv5 from "../../assets/product-sales/adv5.png";

const advantages = [
  {
    title: "Adv1",
    src: Adv1,
    desc: "Innovation Never Ceases",
  },
  {
    title: "Adv2",
    src: Adv2,
    desc: "ISO Standard Quality",
  },
  {
    title: "Adv3",
    src: Adv3,
    desc: "Global Sales Network",
  },
  {
    title: "Adv4",
    src: Adv4,
    desc: "24/7 Technique Support",
  },
  {
    title: "Adv5",
    src: Adv5,
    desc: "Professional Training System",
  },
];

const Advantages = () => {
  return (
    <Container
      sx={{
        background:
          "linear-gradient(180deg, #8DAFBB 0%, rgba(141, 175, 187, 0) 100%)",
        mb: 4,
        pt: 4,
        pb: 4,
      }}
      maxWidth={false}
      disableGutters>
      <Typography variant="h4" textAlign={"center"}>
        Our Advantages
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mt: 6,
          pl: 10,
          pr: 10,
        }}>
        {advantages.map((industry, index) => (
          <Box sx={{ textAlign: "center" }}>
            <img
              src={industry.src}
              alt={industry.title}
              key={index}
              width={"100%"}
            />
            <Typography sx={{ mt: 2 }}>{industry.desc}</Typography>
          </Box>
        ))}
      </Box>
    </Container>
  );
};

export default Advantages;
